import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import { useState } from "react";
import { Alert, Collapse } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const DashboardLogin = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    uid: "",
    pwd: "",
  });
  const [open, setOpen] = useState(false);
  const [mssg, setMssg] = useState("");

  const handleSubmit = async (e) => {
    setMssg("");
    e.preventDefault();
    const res = await fetch("https://earthquake.vr-division.com/dblogin", {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "content-type": "application/json",
      },
    });
    const data = await res.json();
    console.log(data);
    setMssg(data.mssg);
    if (data.token) {
      localStorage.setItem("DB_token", data.token);
      navigate("/");
    }
    openM();
  };

  const openM = () => {
    setTimeout(() => {
      setOpen(true);
    }, 100);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  };

  return (
    <>
      <Helmet>
        <title>Login | VR Division | We Create Digital Experiences</title>
      </Helmet>
      <Container
        maxWidth="xl"
        sx={{
          minHeight: "100vh",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: "100%",
            maxWidth: 500,
            padding: 5,
            borderRadius: "5px",
          }}
        >
          <Typography variant="h5" mb={1} fontWeight={900}>
            Login
          </Typography>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
            onSubmit={handleSubmit}
          >
            <TextField
              sx={{
                mb: 2,
              }}
              fullWidth
              label="Username"
              variant="outlined"
              value={form["uid"]}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, uid: e.target.value }))
              }
            />
            <TextField
              fullWidth
              type={"password"}
              variant="outlined"
              label="Password"
              value={form["pwd"]}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, pwd: e.target.value }))
              }
            />
            <Button
              fullWidth
              variant="contained"
              size="large"
              endIcon={<LoginIcon />}
              sx={{
                mt: 2,
              }}
              type="submit"
            >
              Login
            </Button>
            <Collapse in={open}>
              <Alert
                severity={mssg === "Login success" ? "success" : "error"}
                sx={{ mt: 2 }}
              >
                {mssg}
              </Alert>
            </Collapse>
          </form>
        </Paper>
      </Container>
    </>
  );
};

export default DashboardLogin;
