import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DashboardTable from "../Components/DashboardTable";

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "job",
    numeric: false,
    disablePadding: true,
    label: "Job",
  },
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: true,
    label: "Phone Number",
  },
  {
    id: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Updated At",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  // {
  //   disabled: true,
  // },
];

const Epics = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = async () => {
    const res = await fetch("https://earthquake.vr-division.com/getUsers");
    const d = await res.json();
    setData(
      d.map(({ status, ...rest }) => ({
        ...rest,
        status: status == 1 ? "I Am OK" : status == 2 ? "I Need Help" : "-",
      }))
    );
  };

  return (
    <>
      <Helmet>
        <title>Users | VR Division | We Create Digital Experiences</title>
      </Helmet>
      {/* <Mssg open={open} mssg={mssg} successMssg={successMssg} /> */}
      <DashboardTable
        collapse={false}
        pageName={"Users"}
        headCells={headCells}
        data={data}
      />
    </>
  );
};

export default Epics;
