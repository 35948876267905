import { Navigate, useRoutes } from "react-router-dom";
import { Suspense } from "react";
import { Helmet } from "react-helmet";
import DashboardLayout from "./DashboardLayout";
import DashboardLogin from "./Pages/DashboardLogin";
import DBUsers from "./Pages/DBUsers";

const Router = () => {
  const token = localStorage.getItem("DB_token");

  // const logout = () => {
  //   localStorage.removeItem("DB_token");
  //   localStorage.removeItem("DB_role");
  //   localStorage.removeItem("DB_id");
  //   localStorage.removeItem("DB_name");
  //   navigate("/login");
  // };

  return (
    <>
      <Helmet>
        <link
          rel="shortcut icon"
          href={require("./Assets/Favicon.png")}
          type="image/x-icon"
        />
      </Helmet>
      <Suspense fallback={""}>
        {useRoutes([
          {
            path: "/",
            element: token ? <DashboardLayout /> : <Navigate to={"/login"} />,
            children: [
              {
                path: "",
                element: <Navigate to={"/login"} />,
              },
              {
                path: "users",
                element: <DBUsers />,
              },
              {
                path: "*",
                element: <Navigate to={"/login"} />,
              },
            ],
          },
          {
            path: "/login",
            element: token ? <Navigate to="/users" /> : <DashboardLogin />,
          },
          {
            path: "*",
            element: <Navigate to="/" />,
          },
        ])}
      </Suspense>
    </>
  );
};

export default Router;
