import { Stack, Avatar, Button } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { DashboardMenu } from "./DashboardMenu";
import Mssg from "./Mssg";

const AppBar = () => {
  const navigate = useNavigate();
  const [mssg, setMssg] = useState("");
  const [open, setOpen] = useState(false);
  const [successMssg, setSuccessMssg] = useState("");
  return (
    <Stack
      width="100%"
      direction="row"
      padding={2}
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          onClick={async () => {
            const res = await fetch(
              "https://earthquake.vr-division.com/sendNotif"
            );
            const data = await res.json();
            setMssg(data.mssg);
            setOpen(true);
            setSuccessMssg("Notification sent");
            setTimeout(() => {
              setOpen(false);
            }, 3000);
          }}
        >
          Send Notification
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            const res = await fetch("https://earthquake.vr-division.com/reset");
            const data = await res.json();
            setMssg(data.mssg);
            setOpen(true);
            setSuccessMssg("Reseted");
            setTimeout(() => {
              setOpen(false);
            }, 3000);
          }}
        >
          Reset
        </Button>
      </Stack>
      <Mssg open={open} mssg={mssg} successMssg={successMssg} />
      <Stack direction="row" justifyContent="center" alignItems="center">
        <DashboardMenu
          mainEl={
            <Avatar sx={{ width: 30, height: 30, bgcolor: "primary.main" }} />
          }
          items={[
            {
              inner: "Logout",
              onClick: () => {
                localStorage.removeItem("DB_token");
                localStorage.removeItem("DB_role");
                localStorage.removeItem("DB_id");
                localStorage.removeItem("DB_name");
                navigate("//login");
              },
            },
          ]}
        />
      </Stack>
    </Stack>
  );
};

export default AppBar;
