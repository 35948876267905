import { Box, Stack } from "@mui/material";
import { Outlet } from "react-router-dom";
import AppBar from "./Components/AppBar";

const DashboardLayout = () => {
  return (
    <Stack dir="ltr" height="100vh" direction="row">
      <Box width="100%">
        <AppBar />
        <Stack
          sx={{ overflowY: "auto" }}
          height="calc(100vh - 72px)"
          direction="column"
          paddingX={4}
          paddingY={2}
        >
          <Outlet test="hi" />
        </Stack>
      </Box>
    </Stack>
  );
};

export default DashboardLayout;
